import React from 'react';
import { Link } from 'react-router-dom';
//import { formatter } from '../../utils/requestMethods';

const ServiceLevel = ({ mls }) => {
    //console.log(mls?.Document_pattern);
    
    return (
        <div className="bg-white border border-gray-200 rounded-3xl shadow">
            <div className="m-2 p-5 divide-y">
                <div className="py-2">
                    <h3 className="text-lg font-bold dark:text-gray-200">Service Level Setting</h3>
                    <p>
                        Based on your business type, you will be required to submit the documents below during the business activation process.
                    </p>
                    <hr />
                </div>
            </div>
            <div className="m-2 p-5 divide-y">
                <form>
                    <div className="grid grid-cols-1 gap-6">
                        <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                            <div className="mb-2">
                                <p className="text-gray-700 dark:text-gray-200">Pay Now Transaction Fee</p>
                                <input type="number" placeholder="XXXXXXX" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </div>
                            <div className="mb-2">
                                <p className="text-gray-700 dark:text-gray-200">Flexpay Transaction Fee</p>
                                <input type="number" placeholder="XXXXXXX" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </div>
                        </div>
                        <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                            <div className="mb-2">
                                <p className="text-gray-700 dark:text-gray-200">School Management Transaction Fee/Student</p>
                                <input type="number" placeholder="XXXXXXX" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </div>
                            <div className="mb-2">
                                <p className="text-gray-700 dark:text-gray-200">School Flexpay Mark-Up</p>
                                <input type="number" placeholder="XXXXXXX" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </div>
                        </div>
                        <div className="px-4 py-3 text-right sm:px-6">
                            <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save Details</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ServiceLevel
