import React from "react";
import { FiClock, FiCrosshair, FiHome } from "react-icons/fi";
import {
  BsFillJournalBookmarkFill,
  //BsFillPlusSquareFill,
  BsFillPersonPlusFill,
  BsCurrencyDollar,
  BsFillPeopleFill,
} from "react-icons/bs";
import { IoMdCheckbox, IoMdContacts } from "react-icons/io";

export const links = [
  {
    title: "Onboarding",
    links: [
      {
        name: "Schools",
        icon: <BsFillPeopleFill />,
        url: "customers",
      },
      {
        name: "Institutions",
        icon: <BsFillPersonPlusFill />,
        url: "institutions",
      },
    ],
  },
  {
    title: "Flexpay",
    links: [
      {
        name: "Pending Flexpay",
        icon: <FiClock />,
        url: "pending",
      },
      {
        name: "Approved Flexpay",
        icon: <IoMdCheckbox />,
        url: "approved",
      },
      {
        name: "Rejected Flexpay",
        icon: <FiCrosshair />,
        url: "rejected",
      },
    ],
  },
  {
    title: "Discounts",
    links: [
      {
        name: "Parent Discount",
        icon: <FiClock />,
        url: "discounts",
      },
    ],
  },
  {
    title: "Inflights",
    links: [
      {
        name: "Mandates",
        icon: <BsFillJournalBookmarkFill />,
        url: "mandates",
      },
    ],
  },

  {
    title: "Setting",
    links: [
      {
        name: "Personal",
        icon: <BsFillPersonPlusFill />,
        url: "profile",
      },
    ],
  },
  {
    title: "Docs",
    links: [
      {
        name: "Documentation",
        icon: <BsFillJournalBookmarkFill />,
        url: "docs",
      },
    ],
  },
];

export const themeColors = [
  {
    name: "blue-theme",
    color: "#1A97F5",
  },
  {
    name: "green-theme",
    color: "#03C9D7",
  },
  {
    name: "purple-theme",
    color: "#7352FF",
  },
  {
    name: "red-theme",
    color: "#FF5C8E",
  },
  {
    name: "indigo-theme",
    color: "#1E4DB7",
  },
  {
    color: "#FB9678",
    name: "orange-theme",
  },
];

export const userProfileData = [
  {
    icon: <BsCurrencyDollar />,
    title: "My Profile",
    desc: "Account Settings",
    iconColor: "#03C9D7",
    iconBg: "#E5FAFB",
    link: "/profile",
  },
  {
    icon: <BsFillJournalBookmarkFill />,
    title: "Documentation",
    desc: "Loan under-writing guide",
    iconColor: "rgb(0, 194, 146)",
    iconBg: "rgb(235, 250, 242)",
    link: "/docs",
  },
];

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  "Copy",
  "Edit",
  "Delete",
  "Save",
  "Cancel",
  "PdfExport",
  "ExcelExport",
  "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];
