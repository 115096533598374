import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { userRequest } from '../../utils/requestMethods';
import { Header, ButtonLoading, BackButton } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';

const UpdateClass = ({ history }) => {
  const location = useLocation();
  const classId = location.pathname.split('/')[2];

  const { school } = useStateContext();
  const schoolId = school?._id;

  //const [classes, setClasses] = useState({});
  const [class_name, setClassname] = useState("");
  //const [class_price, setClassPrice] = useState("");
  const [price1, setPrice1] = useState("");
  const [price2, setPrice2] = useState("");
  const [price3, setPrice3] = useState("");
  const [level_of_education, setLevelOfEducation] = useState("");
  //const [refresh, setRefresh] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        setLoading(true);
        const { data } = await userRequest.get(`classes/${schoolId}/${classId}`);
        //console.log(data);
        //setClasses(data);
        setClassname(data.class_name);
        //setClassPrice(data.class_price);
        setPrice1(data.class_price["1"]);
        setPrice2(data.class_price["2"]);
        setPrice3(data.class_price["3"]);
        setLevelOfEducation(data.level_of_education);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchClasses();
  }, [schoolId, classId]);
  
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      await userRequest.put(`classes/${schoolId}/${classId}`,
        { 
          schoolId,
          class_name, 
          class_price: {
            1: price1,
            2: price2,
            3: price3
          },
          level_of_education,
        });
      //console.log(data);
      setSuccess("Class Updated Successfully");
      setTimeout(() => {
        setSuccess("");
        //history.push("/classes");
      }, 5000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
    setLoading(false);
  };

  return (
    <div className="m-2 md:m-10 mt-24  p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <BackButton history={history} />
      <Header category="Set Up" title="Update Class" />
      <div className="py-2 items-right">
        <div className="mt-2 max-w-full">
          <form onSubmit={submitHandler}>
            <div className="grid grid-cols-1 gap-6">
              {error &&
                <div className="alert alert-warning d-flex align-items-center" role="alert">
                  <i className="fal fa-lock pr-10"></i>
                  <div>
                    {error}
                  </div>
                </div>
              }
              {success &&
                <div className="alert alert-success d-flex align-items-center" role="alert">
                  <i className="fal fa-lock pr-10"></i>
                  <div>
                    {success}
                  </div>
                </div>
              }
              <label className="block">
                <span className="text-gray-700">Name of Class <span style={{ color: 'green'}}></span></span>
                <input type="text" onChange={(e) => setClassname(e.target.value)} value={class_name} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
              </label>
              <div className="flex space-x-4">
                <div className="w-1/3">
                    <label htmlFor="price1" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">1st Term Fee</label>
                    <input type="number" id="price1" onChange={(e) => setPrice1(e.target.value)} defaultValue={price1} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="20000"/>
                </div>
                <div className="w-1/3">
                    <label htmlFor="price2" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">2nd Term Fee</label>
                    <input type="number" id="price2" onChange={(e) => setPrice2(e.target.value)} defaultValue={price2} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="20000"/>
                </div>
                <div className="w-1/3">
                    <label htmlFor="price3" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">3rd Term Fee</label>
                    <input type="number" id="price3" onChange={(e) => setPrice3(e.target.value)} defaultValue={price3} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="20000"/>
                </div>
              </div>
              <label className="block">
                <span className="text-gray-700">Level of Education <span style={{ color: 'green'}}></span></span>
                <select onChange={(e) => setLevelOfEducation(e.target.value)} value={level_of_education} className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                  <option value="">Select</option>
                  <option value="pre_school">Pre-School</option>
                  <option value="kindergarten">Kindergarten</option>
                  <option value="nursery">Nursery</option>
                  <option value="primary">Primary</option>
                  <option value="secondary">Secondary</option>
                </select>
              </label>
              <div className="px-4 py-3 text-right sm:px-6"> 
                {loading ?
                  (
                    <ButtonLoading styles="inline-flex justify-center py-3 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600" />
                  ) : (
                    <button type="submit" className="inline-flex justify-center py-3 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Update Class</button>
                  )
                }
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default UpdateClass;
