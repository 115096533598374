import React from 'react'

const BackButton = ({ history }) => {

    const goBack = () => {
        // Go back to the previous page
        history.goBack();
    };

  return (
    <div>
        <button onClick={goBack} className="flex items-center justify-center py-2 mb-2 text-indigo-800"><i className="fad fa-arrow-left mr-2"></i> Back</button>
    </div>
  )
}

export default BackButton