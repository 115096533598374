import React, { useState } from 'react';
import { userRequest } from '../../utils/requestMethods';
import { ButtonLoading } from '../../components';

const KYCDocumentUpload = ({ userId, documents, onDocumentAdded }) => {
  const [documentStates, setDocumentStates] = useState(
    documents.reduce((acc, doc) => {
      acc[doc.docId] = { textData: '', file: null, saving: false, error: '', success: '' };
      return acc;
    }, {})
  );

  const handleFileChange = (docId, e) => {
    const selectedFile = e.target.files[0];
    setDocumentStates((prevState) => ({
      ...prevState,
      [docId]: { ...prevState[docId], file: selectedFile },
    }));
  };

  const handleTextChange = (docId, e) => {
    setDocumentStates((prevState) => ({
      ...prevState,
      [docId]: { ...prevState[docId], textData: e.target.value },
    }));
  };

  const handleSubmit = async (docId, e) => {
    e.preventDefault();
    const { textData, file } = documentStates[docId];

    if (!textData && !file) {
      setDocumentStates((prevState) => ({
        ...prevState,
        [docId]: { ...prevState[docId], error: 'Please provide text data or select a file.', success: '' },
      }));
      setTimeout(() => {
        setDocumentStates((prevState) => ({
          ...prevState,
          [docId]: { ...prevState[docId], success: '', error: '' },
        }));
      }, 3000);
      return;
    }

    const formData = new FormData();
    if (textData) formData.append('textData', textData);
    if (file) formData.append('fileData', file);

    try {
      setDocumentStates((prevState) => ({
        ...prevState,
        [docId]: { ...prevState[docId], saving: true },
      }));
      await userRequest.post(`/schools/${userId}/${docId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setDocumentStates((prevState) => ({
        ...prevState,
        [docId]: { ...prevState[docId], success: 'Document uploaded successfully!', error: '' },
      }));
      setTimeout(() => {
        setDocumentStates((prevState) => ({
          ...prevState,
          [docId]: { ...prevState[docId], success: '', error: '' },
        }));
        onDocumentAdded();
      }, 2000);
    } catch (error) {
      const errorMsg =
        error.response && error.response.status === 500
          ? `Error: ${error.response.data}`
          : 'Error uploading document, try again later';
      setDocumentStates((prevState) => ({
        ...prevState,
        [docId]: { ...prevState[docId], error: errorMsg, success: '' },
      }));
      setTimeout(() => {
        setDocumentStates((prevState) => ({
          ...prevState,
          [docId]: { ...prevState[docId], success: '', error: '' },
        }));
      }, 3000);
    } finally {
      setDocumentStates((prevState) => ({
        ...prevState,
        [docId]: { ...prevState[docId], saving: false },
      }));
    }
  };

  const getStatusBadge = (status, type) => {
    const color = status ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800';
    const label = status ? 'Yes' : 'No';
    return (
      <span className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${color}`}>
        {type}: {label}
      </span>
    );
  };

  const handleDownload = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop(); // Use the last part of the URL as the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="block mb-6">
      {documents.map((doc) => (
        <div key={doc._id} className="block mb-6">
          {documentStates[doc.docId].error && (
            <div className="alert alert-warning flex items-center p-2" role="alert">
              <div>{documentStates[doc.docId].error}</div>
            </div>
          )}
          {documentStates[doc.docId].success && (
            <div className="alert alert-success flex items-center p-2" role="alert">
              <div>{documentStates[doc.docId].success}</div>
            </div>
          )}
          <div className="flex justify-between items-center">
            <div>
              <span className="text-gray-700">{doc.type.replace(/_/g, ' ')}</span>
              <div className="mt-1 text-sm text-gray-500">{doc.description}</div>
            </div>
            <div className="space-x-2">
              {getStatusBadge(doc.submitted.status, 'Submitted')}
              {getStatusBadge(doc.verified.status, 'Verified')}
            </div>
          </div>
          <form onSubmit={(e) => handleSubmit(doc.docId, e)} className="mt-2">
            <div className="lg:grid lg:grid-cols-4 lg:gap-x-2 items-center">
              <div className="lg:col-span-3 mb-2">
                {doc.field_type === 'TEXT' ? (
                  <input
                    type="text"
                    value={documentStates[doc.docId].textData}
                    onChange={(e) => handleTextChange(doc.docId, e)}
                    placeholder="Enter additional text data"
                    className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                  />
                ) : (
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(doc.docId, e)}
                    className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                  />
                )}
              </div>
              <div className="lg:col-span-1 mb-2">
                {documentStates[doc.docId].saving ? (
                  <ButtonLoading styles="inline-flex justify-center py-2 px-8 text-white bg-indigo-800 rounded-full" />
                ) : (
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 bg-indigo-600 text-white rounded-md"
                  >
                    Upload
                  </button>
                )}
                {doc.field_type === 'FILE' && doc.data ? (
                  <button
                    type="button"
                    onClick={() => handleDownload(doc.data)}
                    className="inline-flex justify-center ml-2 py-2 px-4 bg-green-600 text-white rounded-md"
                  >
                    Download File
                  </button>
                ) : doc.field_type === 'TEXT' && doc.data ? (
                  <span className="inline-flex items-center px-3 py-0.5 rounded-full text-md font-medium">
                    {doc.type.replace(/_/g, ' ')}: <span className="font-semibold"> {doc.data}</span>
                  </span>
                ) : (
                  null
                )}
              </div>
            </div>
          </form>
        </div>
      ))}
    </div>
  );
};

const Documents = ({ schoolData, onDocumentAdded }) => {
  return (
    <div className="bg-white border border-gray-200 rounded-3xl shadow">
      <div className="py-2">
        <div className="m-2 p-5 divide-y">
          <div className="grid grid-cols-1 gap-6">
            <KYCDocumentUpload userId={schoolData._id} documents={schoolData.kyc_required_docs.data} onDocumentAdded={onDocumentAdded} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
