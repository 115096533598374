import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";

const TableDropdown = ({ isVisible, position, options, onClose }) => {
  const dropdownRef = useRef(null);

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div
      ref={dropdownRef}
      className="absolute w-56 bg-white border rounded shadow-lg z-50"
      style={{
        top: `${position?.top}px`,
        left: `${position?.left}px`,
        position: "absolute",
      }}
    >
      <ul className="py-1">
        {options.map((option, index) => (
          <li key={index}>
            <button
              className={`flex items-center gap-2 w-full text-left px-4 py-2 text-sm ${
                option.danger
                  ? "text-red-700 hover:bg-red-100"
                  : "text-gray-700 hover:bg-gray-100"
              }`}
              onClick={option.onClick}
            >
              {option.icon && <span>{option.icon}</span>}
              <span>{option.label}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>,
    document.body
  );
};

export default TableDropdown;
