import React from 'react'
import { FiCreditCard, FiEye } from 'react-icons/fi'
import { customFormatter } from '../../utils/currencyUtils'

const AccountDetailsSection = ({ balance, accountNumber, bank }) => {
  return (
    <div className="bg-indigo-800 rounded-3xl shadow-md w-full">
      <div className="bg-indigo-800 text-white rounded-3xl py-2 px-6 items-center justify-between w-full">
        {/* Icon and Last Update */}
        <div className="flex items-center justify-between space-x-4">
          <div className="w-12 h-12 bg-indigo-800 rounded-full flex items-center justify-center">
            <FiCreditCard className="text-white text-2xl" />
          </div>
          <div className="text-gray-300 text-sm">Ledger Balance: {customFormatter(balance?.ledgerBalance)}</div>
        </div>

        <div className="flex items-center my-2 space-x-4">
          <div className="text-3xl font-bold">{customFormatter(balance?.availableBalance)} <FiEye className="ml-2 inline" /></div>
        </div>

        <div className="flex items-center justify-between space-x-4">
          <div className="text-gray-300 text-sm">
            <p className="font-semibold">Account number: {accountNumber || "0000-000-0000"}</p>
          </div>
          <div className="text-lg font-semibold items-center">
            <p className="text-sm">{bank?.name}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountDetailsSection