import React, { useState, useEffect } from 'react'
//import { Link } from 'react-router-dom'
import { useFormik } from "formik";
import { addressSchema } from '../../utils/formSchema';
//import { formatDate } from '../../utils/dateUtils';
import { userRequest } from '../../utils/requestMethods';
//import { useStateContext } from '../../contexts/ContextProvider';
import ButtonLoading from '../ButtonLoading';

const Address = ({ schoolData, address, onAddressAdded }) => {
    //const { school } = useStateContext();
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    //console.log(address);

    const formik = useFormik({
    initialValues: {
        address_line: "",
        city: "",
        state: "",
        zip_code: "",
        address_line2: "",
        city2: "",
        state2: "",
        zip_code2: "",
        //...address,
    },
    validationSchema: addressSchema,
    onSubmit: (values) => {
      // Handle form submission here
      registerHandler(values);
      //console.log(values);
    },
  });

useEffect(() => {
    if (address) {
        formik.setValues({
            address_line: address?.registered?.address_line || "",
            city: address?.registered?.city || "",
            state: address?.registered?.state || "",
            zip_code: address?.registered?.zip_code || "",
            address_line2: address?.main?.address_line || "",
            city2: address?.main?.city || "",
            state2: address?.main?.state || "",
            zip_code2: address?.main?.zip_code || "",
        });
    }
}, [address]);

const registerHandler = async (values) => {
   setLoading(true);

   try {
      await userRequest.put(`/schools/${schoolData._id}`, {
        address: {
            registered: {
              address_line: formik.values.address_line,
              city: formik.values.city,
              state: formik.values.state,
              zip_code: formik.values.zip_code
            },
            main: {
              address_line: formik.values.address_line2,
              city: formik.values.city2,
              state: formik.values.state2,
              zip_code: formik.values.zip_code2
            }
          }
      });

      setSuccess("Address Information added");
      setTimeout(() => {
        setSuccess("");
        //onAddressAdded();
        //navigate("/auth/kyc");
      }, 2000);
   } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
         setError("");
      }, 5000);
   } finally {
    setLoading(false);
  }
};

    return (
        <div className="bg-white border border-gray-200 rounded-3xl shadow">
            {/*address ? (
            <div className="m-4 p-5 divide-y bg-white border border-gray-200 rounded-3xl shadow sm:p-4 dark:bg-gray-800 dark:border-gray-700">
                <div className="flow-root">
                    <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        Register Address
                                    </p>
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {address?.registered.address_line}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        {address?.registered.city}
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                    {address?.registered.state}
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        Operating Address
                                    </p>
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {address?.main.address_line}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        {address?.main.city}
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                    {address?.main.state}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            ) : null */}
            <div className="m-2 p-5 divide-y">
                <div className="py-2">
                    <h3 className="text-lg font-bold dark:text-gray-200">Registered Business Address</h3>
                    <hr />
                </div>
            </div>
            <div className="mx-2 p-5 divide-y">
                {success &&
                    <div className="alert alert-success d-flex align-items-center" role="alert">
                        <i className="fal fa-check pr-10"></i>
                        <div>
                            {success}
                        </div>
                    </div>
                }
                {error && 
                    <div className="alert alert-warning d-flex align-items-center" role="alert">
                        <i className="fal fa-lock pr-10"></i>
                        <div>
                            {error}
                        </div>
                    </div>
                }
                <form onSubmit={formik.handleSubmit}>
                    <div className="grid grid-cols-1 gap-6">
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Address</span>
                            <input type="text" id="address_line" value={formik.values.address_line} onBlur={formik.handleBlur} onChange={formik.handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            {formik.errors.address_line && formik.touched.address_line &&
                                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{formik.errors.address_line}</p>}
                        </label>
                        <div>
                            <p className="text-gray-700 dark:text-gray-200">Location</p>
                            <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                                <div className="mb-2">
                                    <input type="text" id="city" value={formik.values.city} onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="City" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                    {formik.errors.city && formik.touched.city &&
                                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{formik.errors.city}</p>}
                                </div>
                                <div className="">
                                    <input type="text" id="zip_code" value={formik.values.zip_code} onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Postal Code" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                    {formik.errors.zip_code && formik.touched.zip_code &&
                                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{formik.errors.zip_code}</p>}
                                </div>
                            </div>
                        </div>
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">State or Region</span>
                            <select id="state" value={formik.values.state} onBlur={formik.handleBlur} onChange={formik.handleChange} className="bg-gray-100 border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required="">
                                <option value="KANO">KANO</option>
                                <option value="LAGOS">LAGOS</option>
                                <option value="KADUNA">KADUNA</option>
                                <option value="KATSINA">KATSINA</option>
                                <option value="OYO">OYO</option>
                                <option value="RIVERS">RIVERS</option>
                                <option value="BAUCHI">BAUCHI</option>
                                <option value="JIGAWA">JIGAWA</option>
                                <option value="BENUE">BENUE</option>
                                <option value="ANAMBRA">ANAMBRA</option>
                                <option value="BORNO">BORNO</option>
                                <option value="DELTA">DELTA</option>
                                <option value="NIGER">NIGER</option>
                                <option value="IMO">IMO</option>
                                <option value="AKWA_IBOM">AKWA_IBOM</option>
                                <option value="OGUN">OGUN</option>
                                <option value="SOKOTO">SOKOTO</option>
                                <option value="ONDO">ONDO</option>
                                <option value="OSUN">OSUN</option>
                                <option value="KOGI">KOGI</option>
                                <option value="ZAMFARA">ZAMFARA</option>
                                <option value="ENUGU">ENUGU</option>
                                <option value="KEBBI">KEBBI</option>
                                <option value="EDO">EDO</option>
                                <option value="PLATEAU">PLATEAU</option>
                                <option value="ADAMAWA">ADAMAWA</option>
                                <option value="CROSS_RIVER">CROSS_RIVER</option>
                                <option value="ABIA">ABIA</option>
                                <option value="EKITI">EKITI</option>
                                <option value="KWARA">KWARA</option>
                                <option value="GOMBE">GOMBE</option>
                                <option value="YOBE">YOBE</option>
                                <option value="TARABA">TARABA</option>
                                <option value="EBONYI">EBONYI</option>
                                <option value="NASARAWA">NASARAWA</option>
                                <option value="BAYELSA">BAYELSA</option>
                                <option value="FCT">FCT</option>
                            </select>
                            {formik.errors.state && formik.touched.state &&
                                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{formik.errors.state}</p>}
                        </label>
                        <div className="divide-y">
                            <div className="py-2">
                                <h3 className="text-lg font-bold dark:text-gray-200">Operating Business Address</h3>
                                <hr />
                            </div>
                        </div>
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Address</span>
                            <input type="text" id="address_line2" value={formik.values.address_line2} onBlur={formik.handleBlur} onChange={formik.handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            {formik.errors.address_line2 && formik.touched.address_line2 &&
                                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{formik.errors.address_line2}</p>}
                        </label>
                        <div>
                            <p className="text-gray-700 dark:text-gray-200">Location</p>
                            <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                                <div className="mb-2">
                                    <input type="text" id="city2" value={formik.values.city2} onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="City" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                    {formik.errors.city2 && formik.touched.city2 &&
                                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{formik.errors.city2}</p>}
                                </div>
                                <div className="">
                                    <input type="text" id="zip_code2" value={formik.values.zip_code2} onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Postal Code" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                    {formik.errors.zip_code2 && formik.touched.zip_code2 &&
                                        <p className="mt-2 text-sm text-red-600 dark:text-red-500">{formik.errors.zip_code2}</p>}
                                </div>
                            </div>
                        </div>
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">State or Region</span>
                            <select id="state2" value={formik.values.state2} onBlur={formik.handleBlur} onChange={formik.handleChange} className="bg-gray-100 border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required="">
                                <option value="KANO">KANO</option>
                                <option value="LAGOS">LAGOS</option>
                                <option value="KADUNA">KADUNA</option>
                                <option value="KATSINA">KATSINA</option>
                                <option value="OYO">OYO</option>
                                <option value="RIVERS">RIVERS</option>
                                <option value="BAUCHI">BAUCHI</option>
                                <option value="JIGAWA">JIGAWA</option>
                                <option value="BENUE">BENUE</option>
                                <option value="ANAMBRA">ANAMBRA</option>
                                <option value="BORNO">BORNO</option>
                                <option value="DELTA">DELTA</option>
                                <option value="NIGER">NIGER</option>
                                <option value="IMO">IMO</option>
                                <option value="AKWA_IBOM">AKWA_IBOM</option>
                                <option value="OGUN">OGUN</option>
                                <option value="SOKOTO">SOKOTO</option>
                                <option value="ONDO">ONDO</option>
                                <option value="OSUN">OSUN</option>
                                <option value="KOGI">KOGI</option>
                                <option value="ZAMFARA">ZAMFARA</option>
                                <option value="ENUGU">ENUGU</option>
                                <option value="KEBBI">KEBBI</option>
                                <option value="EDO">EDO</option>
                                <option value="PLATEAU">PLATEAU</option>
                                <option value="ADAMAWA">ADAMAWA</option>
                                <option value="CROSS_RIVER">CROSS_RIVER</option>
                                <option value="ABIA">ABIA</option>
                                <option value="EKITI">EKITI</option>
                                <option value="KWARA">KWARA</option>
                                <option value="GOMBE">GOMBE</option>
                                <option value="YOBE">YOBE</option>
                                <option value="TARABA">TARABA</option>
                                <option value="EBONYI">EBONYI</option>
                                <option value="NASARAWA">NASARAWA</option>
                                <option value="BAYELSA">BAYELSA</option>
                                <option value="FCT">FCT</option>
                            </select>
                            {formik.errors.state2 && formik.touched.state2 &&
                                <p className="mt-2 text-sm text-red-600 dark:text-red-500">{formik.errors.state2}</p>}
                        </label>
                        <div className="px-4 py-3 text-right sm:px-6">
                            {loading ?
                                (
                                    <ButtonLoading styles="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-400" />
                                ) : (
                                    <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save Details</button>
                                )
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Address
