import React, { useEffect, useState } from 'react'
import { userRequest } from '../../utils/requestMethods';
import { AccountDetailsSection, Address, Document, Documents, InformationSection, People, SchoolInfoSection, ServiceLevel, TransactionTable } from '../../components';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useStateContext } from '../../contexts/ContextProvider';
import { FiArrowLeft } from 'react-icons/fi';

const ViewSchoolDetails = ({ history }) => {
    const { user } = useStateContext();
    //const schoolId = school?._id
    const location = useLocation();
    const schoolId = location.pathname.split('/')[3];
    console.log(schoolId);
    const [activeTab, setActiveTab] = useState("information");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [schoolData, setSchoolData] = useState(null);
    const [balance, setBalance] = useState({});
    const [success, setSuccess] = useState(false);

    const fetchSchool = async () => {
        try {
            setLoading(true);
            const { data } = await userRequest.get(`/schools/${schoolId}`);
            console.log(data);
            setSchoolData(data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchBalance = async () => {
        try {
            const {data} = await userRequest.get(`schools/${schoolId}/account-balance`);
            console.log(data.data);
            setBalance(data.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchSchool();
        fetchBalance();
    }, [schoolId]);

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
              setLoading(true);
              const { data } = await userRequest.get(`transactions/${user._id}/${schoolId}?&type=school_fee&limit=1000&sort_by=date&order=des`);
              console.log(data);
              setTransactions(data.transactions);
              setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchTransactions();
      }, [user, schoolId]);

    const goBack = () => {
        history.goBack();
    };

    const verifyHandler = async (userId) => {
        try {
            const { data } = await userRequest.post(`/schools/${userId}/kyc-verify`, {
            status: "approved"
            });
            console.log(data);
            fetchSchool();
            setSuccess("KYC details validated successfully!");
            setTimeout(() => {
                setSuccess(false);
            }, 1000);
            //setRefresh(data._id);
        } catch (error) {
            console.log(error);
            if (error.response.data) {
            setError("Error! " + error.response.data);
            setTimeout(() => {
                setError("");
            }, 2000);
            } else {
            setError("Something went wrong, try again later!");
            setTimeout(() => {
                setError("");
            }, 2000);
            }
        }
    };
    
    const anchorHandler = async (userId) => {
        try {
            const { data } = await userRequest.post(`/schools/${userId}/create-account`, {});
            console.log(data);
            fetchSchool();
            setSuccess("Anchor customer Account created successfully!");
            setTimeout(() => {
            setSuccess(false);
        }, 1000);
            //setRefresh(data._id);
        } catch (error) {
            console.log(error);
            if (error.response.data) {
            setError("Error! " + error.response.data);
            setTimeout(() => {
                setError("");
            }, 2000);
            } else {
            setError("Something went wrong, try again later!");
            setTimeout(() => {
                setError("");
            }, 2000);
            }
        }
    };
    
    const validateHandler = async (userId) => {
        try {
            const { data } = await userRequest.post(`/schools/${userId}/validate-kyc`, {});
            console.log(data);
            fetchSchool();
            setSuccess("KYC Validation successful!");
            setTimeout(() => {
                setSuccess(false);
            }, 1000);
            //setRefresh(data._id);
        } catch (error) {
            console.log(error);
            if (error.response.data) {
                setError("Error! " + error.response.data);
                setTimeout(() => {
                    setError("");
                }, 2000);
            } else {
                setError("Something went wrong, try again later!");
                setTimeout(() => {
                    setError("");
                }, 2000);
            }
        }
    };

  return (
    <div>
        <div className="m-4 p-10 min-h-screen">
            <div className="py-2 flex justify-between items-center">
                <div className="my-2">
                    <button onClick={goBack} className="hover:bg-gray-200 px-2 py-1 rounded-full"><FiArrowLeft className="text-2xl font-bold text-gray-900" /></button>
                    <p className="text-gray-500 text-sm mt-2">
                    CRM / <span className="font-semibold">{loading ? ("Loading...") : schoolData?.school_name}</span>
                    </p>
                </div>
                <div>
                    <button onClick={() => validateHandler(schoolData?._id)} className="focus:outline-none mx-2 rounded-full text-white bg-indigo-800 hover:bg-blue-700 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-6 py-2.5 hover:drop-shadow-xl float-right">3. Validate KYC</button>
                    <button onClick={() => anchorHandler(schoolData?._id)} className="focus:outline-none mx-2 rounded-full text-white bg-indigo-800 hover:bg-blue-700 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-6 py-2.5 hover:drop-shadow-xl float-right">2. Create Anchor Account</button>
                    <button onClick={() => verifyHandler(schoolData?._id)} className="focus:outline-none mx-2 rounded-full text-white bg-indigo-800 hover:bg-blue-700 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-6 py-2.5 hover:drop-shadow-xl float-right">1. Verify Details</button>
                </div>
            </div>
            {loading ? (
            // Skeleton Loader
            <div className="animate-pulse">
                <div className="bg-gray-200 rounded-md h-12 w-full my-4"></div>
                <div className="bg-gray-200 rounded-md h-32 w-full my-4"></div>
                <div className="bg-gray-200 rounded-md h-8 w-2/3 my-4"></div>
                <div className="bg-gray-200 rounded-md h-48 w-full my-4"></div>
            </div>
            ) : (
            // Loaded Content
            <>
            {error && 
                <div className="alert alert-warning d-flex align-items-center" role="alert">
                    <i className="fal fa-times pr-10"></i> <div>{error}</div>
                </div>
            }
            <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-4">
                {/* Left Side */}
                <div className="mt-5 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:col-span-2">
                    <SchoolInfoSection
                        schoolData={schoolData}
                        onStatusChange={fetchSchool}
                    />
                </div>

                {/* Right Side */}
                <div className="mt-5 grid grid-cols-1 lg:col-span-1">
                    <AccountDetailsSection
                        balance={balance}
                        accountNumber={schoolData?.account_number}
                        bank={schoolData?.bank}
                    />
                </div>
            </div>
            <div className="bg-white my-4 text-sm font-medium text-center text-gray-500 border-b border-gray-200 rounded-3xl shadow-md">
                <ul className="flex flex-wrap -mb-px">
                    <li className="me-2">
                        <button onClick={() => setActiveTab("information")} className={`inline-block p-4 border rounded-full ${ activeTab === "information" ? "text-indigo-800 bg-indigo-200" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" }`}>
                            Information
                        </button>
                    </li>
                    <li className="me-2">
                        <button onClick={() => setActiveTab("address")} className={`inline-block p-4 border-b-2 rounded-full ${ activeTab === "address" ? "text-indigo-800 bg-indigo-200" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" }`}>
                            Address
                        </button>
                    </li>
                    <li className="me-2">
                        <button onClick={() => setActiveTab("owners")} className={`inline-block p-4 border-b-2 rounded-full ${ activeTab === "owners" ? "text-indigo-800 bg-indigo-200" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" }`}>
                            Owners Details
                        </button>
                    </li>
                    <li className="me-2">
                        <button onClick={() => setActiveTab("documents")} className={`inline-block p-4 border-b-2 rounded-full ${ activeTab === "documents" ? "text-indigo-800 bg-indigo-200" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" }`}>
                            Documents
                        </button>
                    </li>
                    <li className="me-2">
                        <button onClick={() => setActiveTab("transaction")} className={`inline-block p-4 border-b-2 rounded-full ${ activeTab === "transaction" ? "text-indigo-800 bg-indigo-200" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" }`}>
                            Recent Transactions
                        </button>
                    </li>
                    <li className="me-2">
                        <button onClick={() => setActiveTab("disputes")} className={`inline-block p-4 border-b-2 rounded-full ${ activeTab === "disputes" ? "text-indigo-800 bg-indigo-200" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" }`}>
                            Disputes
                        </button>
                    </li>
                    <li className="me-2">
                        <button onClick={() => setActiveTab("sla")} className={`inline-block p-4 border-b-2 rounded-full ${ activeTab === "sla" ? "text-indigo-800 bg-indigo-200" : "border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300" }`}>
                            SLA Setup
                        </button>
                    </li>
                </ul>
            </div>
            {activeTab === "transaction" ? (
                <TransactionTable transactions={transactions} loading={loading} />
            ) : activeTab === "disputes" ? (
                <Document />
            ) : activeTab === "information" ? (
                <InformationSection schoolData={schoolData} onInformationAdded={fetchSchool} />
            ) : activeTab === "owners" ? (
                <People schoolData={schoolData} official={schoolData.officials} onOfficialAdded={fetchSchool} />
            ) : activeTab === "documents" ? (
                <Documents schoolData={schoolData} onDocumentAdded={fetchSchool} />
            ) : activeTab === "address" ? (
                <Address schoolData={schoolData} address={schoolData.address} onAddressAdded={fetchSchool} />
            ) : (
                <ServiceLevel schoolData={schoolData} address={schoolData.address} onAddressAdded={fetchSchool} />
            )}
            </>
            )}
        </div>
    </div>
  )
}

export default ViewSchoolDetails