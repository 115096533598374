import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { Header, SkeletonTable } from "../../components";
import { useStateContext } from "../../contexts/ContextProvider";
import { userRequest } from "../../utils/requestMethods";
//import { MdKeyboardArrowRight } from "react-icons/md";
import { ImSpinner } from "react-icons/im";
import { formatCurrency } from "../../utils/currencyUtils";
import { FiArrowRight } from "react-icons/fi";

const customStyles = {
  headRow: {
    style: {
      backgroundColor: "rgb(248, 250, 252)",
    },
  },
};

const DiscountReconciliation = () => {
  const { user } = useStateContext();
  const [mandates, setMandates] = useState();
  //const [startDate, setStartDate] = useState(new Date());
  //const [endDate, setEndDate] = useState(new Date());
  const [clicked, setClicked] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [kycStatus, setKycStatus] = useState("");

  const fetchTransactions = useCallback(async () => {
    //console.log(kycStatus)
    try {
      setLoading(true);
      const { data } = await userRequest.get(`/transactions`);
      //console.log(data.schools);
      setMandates(data.transactions);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [kycStatus]);

  const fetchDiscounts = useCallback(async () => {
    //console.log(kycStatus)
    try {
      setLoading(true);
      const { data } = await userRequest.get(`/transactions/school/marketing-discounts`);
      console.log(data);
      //setMandates(data.transactions);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [kycStatus]);

  useEffect(() => {
    fetchTransactions();
    fetchDiscounts();
  }, [fetchTransactions]);

  const kycProgressMapping = {
    0: 'Not Started',
    1: 'Details Submitted',
    2: 'Reviewed',
    3: 'Anchor Account Created',
    4: 'Validation In Progress',
    5: 'Awaiting Docs',
    6: 'KYC Validation Failed',
    7: 'KYC Validated',
    8: 'Deposit Account Created'
  };

  const columns = [
    {
      name: "Who Paid?",
      cell: row => {
        const parentName = row.metadata?.parent_name;
        return parentName && parentName !== "undefined undefined" ? parentName : 'N/A';
      },
    },
    {
      name: "Student Name",
      cell: row => row.child ? `${row.child.firstname} ${row.child.lastname}` : 'N/A',
    },
    {
      name: "Student Class",
      cell: row => row.metadata?.class?.class_name || 'N/A',
    },
    {
      name: "Amount Paid",
      cell: row => formatCurrency(row.amount),
    },
    {
      name: "Total Fees",
      cell: row => formatCurrency(row.total_amount),
    },
    {
      name: "Total Paid",
      cell: row => formatCurrency(row.amount_after),
    },
    {
      name: "Discount",
      cell: row => formatCurrency(Number(row.total_amount) - Number(row.amount_after))+ " (5%) ",
    },
    {
      name: "Paid At",
      cell: row => new Date(row.updatedAt).toLocaleString('en-NG', { hour12: true }),
      sortable: true,
    },
    {
      cell: (row) => (
        <Link to={`/discounts`}>
          <button style={{ color: '#1e4db7', backgroundColor: '#91a6f5' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl">
            <FiArrowRight />
          </button>
        </Link>
      ),
      ignoreRowClick: true,
      button: true,
    },
  ]; 

  return (
    <div className="m-3 mt-4 p-2 md:p-10 bg-white rounded-2xl dark:bg-secondary-dark-bg">
      <button
        type="button"
        onClick={() => setClicked(!clicked)}
        className="mb-10 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-800 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right"
      >
        Filters
      </button>
      <Header category="Discounts" title="Parent Discount" />
      {/*<h1 className="text-3xl font-extrabold tracking-tight text-slate-900 pl-2 pt-4 dark:text-gray-200">
        Welcome, {user?.firstname}!
      </h1>
      <p className="tracking-tight text-slate-600 pl-2 dark:text-gray-200">
        In-depth analysis of customers financial transactions
      </p>*/}
      <div className="py-4 overflow-scroll md:overflow-hidden">
        {clicked ? (
          <div className="grid gap-6 mb-6 md:grid-cols-4">
            <div>
              <label
                htmlFor="startDate"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                KYC Status
              </label>
              <select id="kycStatus" onChange={(e) => setKycStatus(e.target.value)} className="bg-gray-100 border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                <option value="">Select Status</option>
                <option value="approved">Approved</option>
                <option value="pending">Pending</option>
                <option value="rejected">Rejected</option>
                <option value="not_started">Not Started</option>
              </select>
            </div>
            {/*<div>
              <label htmlFor="endDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Name of School
              </label>
              <input
                type="text"
                id="kycStatus"
                onChange={(e) => setKycStatus(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
              />
            </div>
            <div className="mt-6">
              <button
                type="submit"
                disabled={loading}
                // onClick={submitHandler}
                className="py-2 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 disabled:bg-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Search
              </button>
            </div>*/}
          </div>
        ) : null}
        {success &&
          <div className="alert alert-success d-flex align-items-center" role="alert">
            <i className="fal fa-check pr-10"></i>
            <div>
              Success! {success}
            </div>
          </div>
        }
        {error &&
          <div className="alert alert-warning d-flex align-items-center" role="alert">
            <i className="fal fa-lock pr-10"></i>
            <div>
              Error Occurred: {error}
            </div>
          </div>
        }
        <div className="border rounded-3xl">
          <DataTable
            data={mandates}
            columns={columns}
            getRowId={(row) => row._id}
            pagination
            customStyles={customStyles}
            progressPending={loading}
            progressComponent={<SkeletonTable rows={5} columns={6} />}
            highlightOnHover
            striped
          />
        </div>
      </div>
    </div>
  );
};

export default DiscountReconciliation;
