import React from 'react'
import { FiPlus } from 'react-icons/fi'

const SchoolInfoSection = ({ schoolData }) => {
    const kycProgressMapping = {
        0: 'Not Started',
        1: 'Details Submitted',
        2: 'Reviewed',
        3: 'Anchor Account Created',
        4: 'Validation In Progress',
        5: 'Awaiting Docs',
        6: 'KYC Validation Failed',
        7: 'KYC Validated',
        8: 'Deposit Account Created'
    };

    return (
        <div className="lg:col-span-2 bg-white rounded-3xl shadow-md items-center justify-center">
            <div className="space-y-2 items-center justify-between p-4 space-x-4">
                <div className="flex items-center justify-between py-3 px-6">
                    <div className="flex space-x-6">
                        <div className="relative flex items-center justify-center">
                            <img 
                                src="https://acceede.com/icon.svg" 
                                alt="Business Icon" 
                                className="flex items-center justify-center rounded-full w-10 h-10" 
                                width={80} 
                            />
                            <FiPlus className="absolute bottom-1 right-1 w-6 h-6 text-indigo-800 bg-indigo-200 rounded-full p-1" />
                        </div>
                        <div>
                            <h1 className="text-xl font-bold text-gray-900">{schoolData?.school_name}</h1>
                            <p className="text-gray-500 text-sm">ID: {schoolData?._id}</p>
                            <p className="text-gray-500 text-sm">Email: {schoolData?.school_email}</p>
                            <p className="text-gray-500 text-sm">Phone: {schoolData?.phone_number}</p>
                            <p className="text-gray-500 text-sm">Created date: {new Date(schoolData?.createdAt).toLocaleDateString("en-NG")}</p>
                        </div>
                    </div>
                    <div>
                        <button style={schoolData?.kyc_status === "approved" ? { background: 'rgb(139, 231, 139)' } : schoolData?.kyc_status === "pending" ? { background: 'rgb(227, 160, 8)' } : { background: 'rgb(227, 79, 79)' }} className="block text-white py-1 px-2 mb-2 capitalize rounded-2xl text-sm">{schoolData?.kyc_status === "approved" ? ("Approved") : schoolData?.kyc_status === "pending" ? ("Pending") : ("Not Started")}</button>
                        <p className="block text-md font-semibold">{kycProgressMapping[schoolData?.kyc_progress]}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SchoolInfoSection