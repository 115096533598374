import React, { useState } from 'react'
//import { Link } from 'react-router-dom'
import { useFormik } from "formik";
import { formatDate } from '../../utils/dateUtils';
import { userRequest } from '../../utils/requestMethods';
//import { useStateContext } from '../../contexts/ContextProvider';
import ButtonLoading from '../ButtonLoading';
import { officialsSchema } from '../../utils/formSchema';

const People = ({ schoolData, official, onOfficialAdded }) => {
    //console.log(official?.length);
    //const { school } = useStateContext();
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [editingOfficialId, setEditingOfficialId] = useState(null); // State for editing official ID

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
        setFieldValue,
    } = useFormik({
        initialValues: {
            role: "",
            first_name: "",
            last_name: "",
            date_of_birth: "",
            email: "",
            phone_number: "",
            nationality: "",
            city: "",
            state: "",
            zip_code: "",
            address_line: "",
            title: "",
            percentage: "",
            bvn: "",
        },
        validationSchema: officialsSchema,
        onSubmit: (values) => {
            registerHandler(values);
        },
    });

    const handleEditClick = (official) => {
        setFieldValue("role", official.role);
        setFieldValue("first_name", official.first_name);
        setFieldValue("last_name", official.last_name);
        setFieldValue("date_of_birth", formatDate(official.date_of_birth));
        setFieldValue("email", official.email);
        setFieldValue("phone_number", official.phone_number);
        setFieldValue("nationality", official.nationality);
        setFieldValue("city", official.address.city);
        setFieldValue("state", official.address.state);
        setFieldValue("zip_code", official.address.zip_code);
        setFieldValue("address_line", official.address.address_line);
        setFieldValue("title", official.title);
        setFieldValue("percentage", official.percentage);
        setFieldValue("bvn", official.bvn);
        setEditingOfficialId(official._id);
    };

    const registerHandler = async (values) => {
        setLoading(true);

        try {
            const allValues = {
                ...values,
                date_of_birth: formatDate(values.date_of_birth),
            };

            let response;
            if (editingOfficialId) {
                response = await userRequest.put(`schools/${schoolData._id}`, {
                    officials: [
                        {
                            role: allValues.role,
                            first_name: allValues.first_name,
                            last_name: allValues.last_name,
                            middle_name: allValues.middle_name,
                            maiden_name: allValues.maiden_name,
                            date_of_birth: allValues.date_of_birth,
                            email: allValues.email,
                            phone_number: allValues.phone_number,
                            nationality: allValues.nationality,
                            address: {
                                address_line: allValues.address_line,
                                city: allValues.city,
                                state: allValues.state,
                                zip_code: allValues.zip_code,
                            },
                            bvn: allValues.bvn,
                            title: allValues.title,
                            percentage: allValues.percentage,
                            _id: editingOfficialId,
                        }
                    ]
                });
            } else {
                response = await userRequest.put(`schools/${schoolData._id}`, {
                    officials: [
                        {
                            role: allValues.role,
                            first_name: allValues.first_name,
                            last_name: allValues.last_name,
                            middle_name: allValues.middle_name,
                            maiden_name: allValues.maiden_name,
                            date_of_birth: allValues.date_of_birth,
                            email: allValues.email,
                            phone_number: allValues.phone_number,
                            nationality: allValues.nationality,
                            address: {
                                address_line: allValues.address_line,
                                city: allValues.city,
                                state: allValues.state,
                                zip_code: allValues.zip_code,
                            },
                            bvn: allValues.bvn,
                            title: allValues.title,
                            percentage: allValues.percentage,
                        }
                    ]
                });
            }

            setSuccess(editingOfficialId ? "Official Information updated" : "Official Information added");
            setTimeout(() => {
                setSuccess("");
                resetForm();
                setEditingOfficialId(null);
                onOfficialAdded();
            }, 2000);
        } catch (error) {
            console.log(error);
            setError("Something went wrong, try again in a few minutes!");
            setTimeout(() => {
                setError("");
            }, 5000);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-white border border-gray-200 rounded-3xl shadow">
            {official && official.length > 0 && (
                <div className="m-4 p-5 divide-y p-4 bg-white border border-gray-200 rounded-3xl shadow sm:p-4 dark:bg-gray-800 dark:border-gray-700">
                    <div className="flow-root">
                        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                            {official.map((people) => (
                                <li key={people.phone_number} className="py-3 sm:py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="flex-1 min-w-0">
                                            <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                                {people.first_name} {people.last_name}
                                            </p>
                                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                {people.email}
                                            </p>
                                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                {people.title} - {people.percentage}%
                                            </p>
                                        </div>
                                        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                            {people.role}
                                        </div>
                                        <button
                                            className="inline-flex items-center px-3 py-1 text-sm font-medium text-gray-900 bg-gray-200 rounded hover:bg-gray-300"
                                            onClick={() => handleEditClick(people)}
                                        >
                                            Edit
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
            <div className="py-2 items-right">
                <div className="m-3 p-5 divide-y">
                    {success &&
                        <div className="alert alert-success d-flex align-items-center" role="alert">
                            <i className="fal fa-check pr-10"></i>
                            <div>
                                {success}
                            </div>
                        </div>
                    }
                    {error && 
                        <div className="alert alert-warning d-flex align-items-center" role="alert">
                            <i className="fal fa-lock pr-10"></i>
                            <div>
                                {error}
                            </div>
                        </div>
                    }
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 gap-6">
                            <div>
                                <p className="text-gray-700 dark:text-gray-200">Full Name</p>
                                <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                                    <div className="mb-2">
                                        <input type="text" id="first_name" value={values.first_name} onBlur={handleBlur} onChange={handleChange} placeholder="First Name" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                        {errors.first_name && touched.first_name &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.first_name}</p>}
                                    </div>
                                    <div className="">
                                        <input type="text" id="last_name" value={values.last_name} onBlur={handleBlur} onChange={handleChange} placeholder="Last Name" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                        {errors.last_name && touched.last_name &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.last_name}</p>}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                                    <div className="mb-2">
                                        <p className="text-gray-700 dark:text-gray-200">Role</p>
                                        <select id="role" value={values.role} onBlur={handleBlur} onChange={handleChange} className="bg-gray-100 border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required="">
                                        <option value="">Role</option>
                                        <option value="DIRECTOR">DIRECTOR</option>
                                        <option value="OWNER">OWNER</option>
                                        </select>
                                        {errors.role && touched.role &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.role}</p>}
                                    </div>
                                    <div className="">
                                        <p className="text-gray-700 dark:text-gray-200">Percentage Owned</p>
                                        <input type="number" id="percentage" value={values.percentage} onBlur={handleBlur} onChange={handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                        {errors.percentage && touched.percentage &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.percentage}</p>}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                                    <div className="mb-2">
                                        <p className="text-gray-700 dark:text-gray-200">Title</p>
                                        <select id="title" value={values.title} onBlur={handleBlur} onChange={handleChange} className="bg-gray-100 border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required="">
                                            <option value=""></option>
                                            <option value="CEO">CEO</option>
                                            <option value="COO">COO</option>
                                            <option value="CFO">CFO</option>
                                            <option value="President">President</option>
                                            <option value="CIO">CIO</option>
                                            <option value="VP">VP</option>
                                            <option value="Treasurer">Treasurer</option>
                                            <option value="Controller">Controller</option>
                                            <option value="Manager">Manager</option>
                                            <option value="Partner">Partner</option>
                                            <option value="Member">Member</option>
                                        </select>
                                        {errors.title && touched.title &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.title}</p>}
                                    </div>
                                    <div className="">
                                        <p className="text-gray-700 dark:text-gray-200">BVN</p>
                                        <input type="text" id="bvn" value={values.bvn} onBlur={handleBlur} onChange={handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                        {errors.bvn && touched.bvn &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.bvn}</p>}
                                    </div>
                                </div>
                            </div>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Email</span>
                                <input type="email" id="email" value={values.email} onBlur={handleBlur} onChange={handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                {errors.email && touched.email &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.email}</p>}
                            </label>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Phone Number</span>
                                <input type="tel" id="phone_number" value={values.phone_number} onBlur={handleBlur} onChange={handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                {errors.phone_number && touched.phone_number &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.phone_number}</p>}
                            </label>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Date of Birth</span>
                                <input type="date" id="date_of_birth" value={values.date_of_birth} onBlur={handleBlur} onChange={handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                {errors.date_of_birth && touched.date_of_birth &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.date_of_birth}</p>}
                            </label>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Nationality</span>
                                <select id="nationality" value={values.nationality} onBlur={handleBlur} onChange={handleChange} className="bg-gray-100 border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required="">
                                    <option value="">Nationality</option>
                                    <option value="NG">Nigerian</option>
                                </select>
                                {errors.nationality && touched.nationality &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.nationality}</p>}
                            </label>
                            <div className="mt-2 max-w-xl divide-y">
                                <div className="py-2">
                                    <h3 className="text-1xl font-bold dark:text-gray-200">Owners/Directors Address</h3>
                                    <hr />
                                </div>
                            </div>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Address</span>
                                <input type="text" id="address_line" value={values.address_line} onBlur={handleBlur} onChange={handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                {errors.address_line && touched.address_line &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.address_line}</p>}
                            </label>
                            <div>
                                <p className="text-gray-700 dark:text-gray-200">Location</p>
                                <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                                    <div className="mb-2">
                                        <input type="text" id="city" value={values.city} onBlur={handleBlur} onChange={handleChange} placeholder="City" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                        {errors.city && touched.city &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.city}</p>}
                                    </div>
                                    <div className="">
                                        <input type="text" id="zip_code" value={values.zip_code} onBlur={handleBlur} onChange={handleChange} placeholder="Postal Code" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                        {errors.zip_code && touched.zip_code &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.zip_code}</p>}
                                    </div>
                                </div>
                            </div>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">State or Region</span>
                                <select id="state" value={values.state} onBlur={handleBlur} onChange={handleChange} className="bg-gray-100 border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required="">
                                    <option value="KANO">KANO</option>
                                    <option value="LAGOS">LAGOS</option>
                                    <option value="KADUNA">KADUNA</option>
                                    <option value="KATSINA">KATSINA</option>
                                    <option value="OYO">OYO</option>
                                    <option value="RIVERS">RIVERS</option>
                                    <option value="BAUCHI">BAUCHI</option>
                                    <option value="JIGAWA">JIGAWA</option>
                                    <option value="BENUE">BENUE</option>
                                    <option value="ANAMBRA">ANAMBRA</option>
                                    <option value="BORNO">BORNO</option>
                                    <option value="DELTA">DELTA</option>
                                    <option value="NIGER">NIGER</option>
                                    <option value="IMO">IMO</option>
                                    <option value="AKWA_IBOM">AKWA_IBOM</option>
                                    <option value="OGUN">OGUN</option>
                                    <option value="SOKOTO">SOKOTO</option>
                                    <option value="ONDO">ONDO</option>
                                    <option value="OSUN">OSUN</option>
                                    <option value="KOGI">KOGI</option>
                                    <option value="ZAMFARA">ZAMFARA</option>
                                    <option value="ENUGU">ENUGU</option>
                                    <option value="KEBBI">KEBBI</option>
                                    <option value="EDO">EDO</option>
                                    <option value="PLATEAU">PLATEAU</option>
                                    <option value="ADAMAWA">ADAMAWA</option>
                                    <option value="CROSS_RIVER">CROSS_RIVER</option>
                                    <option value="ABIA">ABIA</option>
                                    <option value="EKITI">EKITI</option>
                                    <option value="KWARA">KWARA</option>
                                    <option value="GOMBE">GOMBE</option>
                                    <option value="YOBE">YOBE</option>
                                    <option value="TARABA">TARABA</option>
                                    <option value="EBONYI">EBONYI</option>
                                    <option value="NASARAWA">NASARAWA</option>
                                    <option value="BAYELSA">BAYELSA</option>
                                    <option value="FCT">FCT</option>
                                </select>
                                {errors.state && touched.state &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">{errors.state}</p>}
                            </label>
                            <div className="px-4 py-3 text-right sm:px-6">
                                {loading ?
                                    (
                                        <ButtonLoading styles="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-400" />
                                    ) : (
                                        <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save Details</button>
                                    )
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default People
