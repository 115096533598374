import React from "react";

const SkeletonTable = ({
  rows = 5,                    // Number of skeleton rows
  columns = 5,                 // Number of columns for the skeleton
  tableWidth = "min-w-full",   // Custom width for the table
  cellPadding = "p-4",         // Padding for cells
  animationClass = "animate-pulse", // CSS animation class for loading effect
}) => {
  return (
    <table className={`${tableWidth} divide-y divide-gray-200`}>
      <thead>
        <tr>
          {Array.from({ length: columns }).map((_, index) => (
            <th key={index} className={cellPadding}>
              <div className={`h-4 bg-gray-300 rounded w-3/4 ${animationClass}`}></div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from({ length: rows }).map((_, rowIndex) => (
          <tr key={rowIndex}>
            {Array.from({ length: columns }).map((_, colIndex) => (
              <td key={colIndex} className={cellPadding}>
                <div className={`h-4 bg-gray-200 rounded w-full ${animationClass}`}></div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SkeletonTable;
